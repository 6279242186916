import React from 'react';

const WhoWeAre = () => (
    <div className="whoWeAre" id="whoWeAre">
        <h2>ΠΟΙΟΙ ΕΙΜΑΣΤΕ</h2>
        <p>
            Οι εργολάβοι οικοδομών ''Αφοι ΙΣΜΑΪΛΑΝΗ'', διαθέτοντας εμπειρία ετών και την απαραίτητη τεχνογνωσία, ασχολούνται από το 1995 με οικοδομικές εργασίες παντός τύπου, gunite (γκανάϊτ), υδροαμμοβολές και με αναπαλαιώσεις κτιρίων στο Αλιβέρι, τις γύρω περιοχές και κατ' επέκταση σε όλη την Εύβοια.
        </p>
        <p>
            Αναλαμβάνουμε την κατασκευή από κτισίματα, επιχρίσματα, κάθε οικοδομήματος, είτε ιδιώτη είτε επαγγελματία, ανακαινίσεις καταστημάτων, χωρίσματα, θερμομονώσεις, θερμοπροσόψεις, αναβαθμίζοντας ενεργειακά το οικοδόμημα, τσιμεντοκονίες, ελαιοχρωματισμούς, επενδύσεις πέτρας.
        </p>
        <p>
            Αναλαμβάνουμε την αποπεράτωση οποιοδήποτε έργου με συνέπεια, αξιοπιστία και με εγγυημένο αποτέλεσμα χάρη στο εξειδικευμένο συνεργείο που διαθέτουμε από έμπειρους τεχνίτες και φυσικά χρησιμοποιώντας τα καλύτερα υλικά, καθώς στόχος μας είναι όχι μόνο ένα αισθητικό αποτέλεσμα αλλά και η σωστή κατασκευή του.
        </p>
        <p>
            Επίσης ασχολούμαστε με το κομμάτι των αναπαλαιώσεων. Μπορούμε να αναπαλαιώσουμε κτίρια παντός τύπου καθώς μαζί αποφασίζουμε για το πως θα διαμορφωθεί εκ νέου ο χώρος, συνδυάζοντας τις γνώσεις μας και τις δικές σας επιθυμίες.
        </p>
        <p>
            Γρήγορα, ποιοτικά και με πολύ καλές τιμές.
        </p>
    </div>
);
 
export default WhoWeAre;
