import React from 'react';
import Layout from '../comp/Layout';
import styles from './index.module.scss';
import Carusel from '../comp/Carusel';
import WhoWeAre from '../comp/WhoWeAre';
import OurServices from '../comp/OurServices';

const IndexPage = () => (
  <Layout title="Αρχική">
    <main className={`${styles.mainContainer} page-margin`}>
      <Carusel />
      <WhoWeAre />
      <OurServices />
    </main>
  </Layout>
);

export default IndexPage;
