import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import image1 from '../images/hero/20180215_222239_2.webp';
import image2 from '../images/hero/IMG_20200505_092414.webp';
import image3 from '../images/hero/20191115_095757.webp';
import image4 from '../images/hero/20210308_142048.webp';
import image5 from '../images/hero/DSCN6830.webp';
import image6 from '../images/hero/IMG_20180724_185315.webp';
import image7 from '../images/hero/IMG_20180907_180106_038.webp';
import image8 from '../images/hero/IMG_20190409_151344.webp';
import image9 from '../images/hero/IMG-39a6f304dbe5414f4ad4c9f894cee7a3-V.webp';

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9];
// const imagesTexts = ['image1', 'image2', 'image3', 'image4', 'image5','image8','image9'];


const Carusel = () => (
    <Carousel 
        autoPlay={true}
        infiniteLoop={true}
        transitionTime={1000}
        interval={3500}
        thumbWidth={0}
        showThumbs={false}
        showIndicators={true}
        showStatus={false}
        dynamicHeight={true}
        centerMode={false}>
        {images.map( (image, index) => (
            <div className="carousel-img" style={{backgroundImage: 'url('+image+')'}}>
                {/* <p className="legend">{imagesTexts[index]}</p> */}
            </div>  
        ))}    
    </Carousel>
);

export default Carusel;
