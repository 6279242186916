import React from 'react';


const OurServices = () => (
    <div className="our-services" id="services">
        <h2>ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ</h2>
        <p>
            <ul>
                <li>Αμμοβολές</li>
                <li>Υδροβολές</li>
                <li>Γεμίσματα δαπέδων με πρέσα (αφρό μπετό, περλομπετόν και τσιμετοκονιά)</li>
                <li>Εκτοξευόμενο σκυρόδεμα - Gunite (γκαναίτ)</li>
                <li>Επισκευές - Ενισχύσεις Κτηρίου</li>
                <li>Τσιμεντοενέσεις</li>
                <li>Χτισίματα</li>
                <li>Σοβατίσματα</li>
                <li>Θερμοπροσώωεις</li>
                <li>Ενοικιάσεις - Τοποθέτηση Σκαλωσιών</li>
                <li>Ελαιοχρωματισμοί</li>
                <li>Κατασκευές Μπετόν</li>
                <li>Ανακαινίσεις - Αναπαλαιώσεις Κτηρίων</li>
            </ul>
        </p>

        <div className="main-button"><a href="/album" alt="">Δείτε τη συλλογή</a></div>
    </div>
);

export default OurServices;
